import React from 'react'

import Layout from 'layouts/index'
import Fade from 'react-reveal/Fade'

import TitleBox from 'components/TitleBox'
import {
  PageContainer,
  ContentWrapper,
  ContentText,
} from 'pageStyles/sobre-nos.styles'

import internament from 'images/internamento_1.jpg'

const Internamento = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Internamento' bg={internament} />
        <PageContainer>
          <Fade>
            <ContentWrapper>
              <ContentText>
                <p>
                  O desenvolvimento de uma doença em nossos pets é sempre um
                  momento delicado. Em algumas situações, isso pode se agravar,
                  pois é inevitável a permanecia deles em hospitalização.
                </p>
                <p>
                  Os médicos veterinários da internação, estarão em contato
                  direto com o médico responsável pelo atendimento inicial,
                  discutindo a evolução clínica, requisitando exames, e
                  informando a todo o momento os tutores do andamento dos
                  procedimentos e da evolução do paciente.
                </p>
                <p>
                  Para que tudo funcione de maneira adequada, nossas instalações
                  são compostas por leitos individuais e separado em alas.
                </p>
                <p>
                  Para que tudo funcione de maneira adequada, nossas instalações
                  de destacam!
                </p>
                <p>
                  Nossos pacientes possuem conforto, aquecimento e arejamento
                  adequados para cada momento. Permanecem acolchoados com
                  cobertores e com tapetes higiênicos absorventes.
                </p>
                <p>
                  Todos os leitos possuem iluminação própria com padronização de
                  prontuários e identificação. Saídas de oxigênio estão próximas
                  e de fácil acesso a todos os leitos.
                </p>
                <p>
                  Os pacientes são hospitalizados e conectados a bomba de
                  infusão, aparelho específico que auxilia na administração dos
                  fluidos e medicações de forma mais adequada.
                </p>
                <p>
                  Doenças infectocontagiosas, permanecem em total isolamento dos
                  demais, e os gatos possuem uma ala somente deles.
                </p>
                <p>Local do Leito e do Ambiente de Internação:</p>
                <p>
                  Dentro do ambiente, há banheiras de higienização e estações de
                  trabalho para procedimentos como acesso venoso, coleta de
                  sangue e curativos. Na internação Derosso, fique tranquilo,
                  seu pet estará em ótimas mãos!!!
                </p>
              </ContentText>
            </ContentWrapper>
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default Internamento
